// Import the axios library for making HTTP requests
import axios from 'axios';

// Initial state
/** @type {{coordinates: Array, chartReady: Boolean, loading: Boolean}} */
const state = {
  coordinates: [],
  chartReady: false,
  loading: true,
};

// Getters
/** @type {{coordinates: Function, chartReady: Function, loading: Function}} */
const getters = {
  coordinates: (state) => state.coordinates,
  chartReady: (state) => state.chartReady,
  loading: (state) => state.loading,
};

// Actions
/** @type {{fetchCoordinates: Function}} */
const actions = {
  /**
   * Fetch the coordinates data from the API.
   * @param {Function} commit - Vuex commit function
   * @param {Object} payload - Payload containing fromDate, toDate, and selectedStations
   * @throws {Error} If fromDate or toDate are not defined
   */
  async fetchCoordinates({ commit }, { fromDate, toDate, selectedStations = [] }) {
    try {
      // Validate input
      if (!fromDate || !toDate) {
        throw new Error("Dates are not properly set. Fetching is cancelled.");
      }

      const payload = {
        from_date: fromDate,
        to_date: toDate,
        stations: selectedStations.length > 0 ? selectedStations : [],
      };

      // Delay the action execution
      const debounceTime = 0; 
      if (!this._timer) {
        this._timer = setTimeout(async () => {
          this._timer = null;

          commit("setLoading", true);
          commit("setChartReady", false);

          const response = await axios.post("https://ildkule.net/api/insight/coordinates", payload);
          
          commit("setCoordinates", response.data);
          commit("setChartReady", true);
          commit("setLoading", false);

        }, debounceTime);
      }
    } catch (error) {
      console.error("Error fetching coordinates data:", error);
      commit("setChartReady", false);
      commit("setLoading", false);
    }
  },
};

// Mutations
/** @type {{setCoordinates: Function, setChartReady: Function, setLoading: Function}} */
const mutations = {
  /**
   * Set the coordinates data in the state.
   * @param {Object} state - Vuex state object
   * @param {Array} coordinates - Array of coordinates data
   */
  setCoordinates(state, coordinates) {
    state.coordinates = coordinates;
  },

  /**
   * Set the chartReady flag in the state.
   * @param {Object} state - Vuex state object
   * @param {Boolean} ready - Flag to determine if the chart is ready
   */
  setChartReady(state, ready) {
    state.chartReady = ready;
  },

  /**
   * Set the loading flag in the state.
   * @param {Object} state - Vuex state object
   * @param {Boolean} loading - Flag to determine if the data is still loading
   */
  setLoading(state, loading) {
    state.loading = loading;
  },
};

// Export the dashboard module
/** @type {Object} */
export const dashboard = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
