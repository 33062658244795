<template>
  <div class="card"  >
    <img
      class="img-fluid"
      :src="
        'https://ildkule.net/data/' +
        currentMeteor.datetimetag.substring(0, 8) +
        '/' +
        currentMeteor.datetimetag.substring(8) +
        '/image.jpg'
      "      
      alt="Card image cap"
    />
    <a
      class="btn-floating btn-large"
      @click="close"
      style="margin-top: 3%"
    >
      <i
        class="fa fa-window-close fa-2xl"
        style="color: darkgrey"
        :class="{ abs: currentMeteor }"
      >
      </i>
    </a>

    <div class="card-body">
      <div>
        <i class="fa fa-clock"></i>
        {{ convertToLocalDate(currentMeteor.date) }} Lokaltid <br />
        <i class="fa fa-clock"></i> {{ currentMeteor.date }} UTC <br />
        <i class="fa-solid fa-location-dot"></i>
        {{ currentMeteor.location ? currentMeteor.location : "Ukjent" }}
        <br />
        <br />
      </div>

      <h5 v-if="currentUser">Meteorvurdering</h5>
      <small>Ser dette ut som en meteor?</small>
      <div
        v-if="currentUser"
        class="btn-group w-100"
        role="group"
        aria-label="classifyBtn"
      >
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="classifyMeteor(currentMeteor.id, 'Positive')"
        >
          Ja
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="classifyMeteor(currentMeteor.id, 'Negative')"
        >
          Nei
        </button>
        <button
          v-if="showClearReviewButton"
          type="button"
          class="btn btn-secondary btn-sm"
          @click="classifyMeteor(currentMeteor.id, 'Clear')"
        >
          <small>Nullstill valg</small>
        </button>
        <br />
      </div>

      <b-alert v-model="showDismissibleAlert" variant="info" dismissible>
        {{ messageFromReview }}
      </b-alert>

      <div v-if="currentMeteorData" style="padding-top: 25px">
        <h5>Observasjonspunkter</h5>
        <table
          v-if="currentMeteorData.observation_cam_data"
          class="table table-striped table-bordered"
        >
          <tbody>
            <tr>
              <th>Kamera</th>
              <th>Stasjon</th>
              <th>Ildkule</th>
            </tr>
            <tr
              v-for="(
                cam_data, index
              ) in currentMeteorData.observation_cam_data"
              :key="index"
            >
              <td>{{ cam_data.cam.cam_name }}</td>
              <td>{{ cam_data.cam.station.station_name }}</td>

              <td style="text-align: center">
                <img
                  border="0"
                  class="img-fluid"                 
                  alt="Ildkulebilde"
                  name="Ildkulebilde"
                  :src="
                    'https://ildkule.net/data/' +
                    currentMeteor.datetimetag.substring(0, 8) +
                    '/' +
                    currentMeteor.datetimetag.substring(8) +
                    '/' +
                    cam_data.cam.station.station_name +
                    '/' +
                    cam_data.cam.cam_name +
                    '/' +
                    'fireball.jpg'
                  "
                  onerror="this.onerror=null;this.src='img/ildkule-20121112-mini.1818dfc5.jpg';" 
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h5 v-if="currentMeteor.track_startheight">Meteorens atmosfæriske bane</h5>
      <table
        v-if="currentMeteor.track_startheight"
        class="table table-striped table-bordered"
      >
        <tbody>
          <tr>
            <td>Starthøyde</td>
            <td>{{ currentMeteor.track_startheight }} km</td>
          </tr>
          <tr>
            <td>Slutthøyde</td>
            <td>{{ currentMeteor.track_endheight }} km</td>
          </tr>
        </tbody>
      </table>

      <hr />
      <div v-if="currentMeteorData">
        <div
          v-for="cdata in currentMeteorData.observation_cam_data"
          :key="cdata.id"
        >
          <video class="video" style="width: 100%; height: auto" controls>
            <source
              v-if="cdata.videourl"
              :src="cdata.videourl"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <div>
            <!--<video
                      v-for="(
                        cdata, index
                      ) in currentMeteorData.observation_cam_data"
                      :key="index"
                      class="video"
                      style="width: 100%; height: auto"
                      controls
                    >
                      <source
                      :key="index"
                        v-if="cdata.videourl"                         
                        :src="cdata.videourl"                         
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video> -->
          </div>
        </div>
      </div>

      <div v-if="currentMeteor.track_startheight">
        <img
          class="img-fluid"
          :src="
            'https://ildkule.net/data/' +
            currentMeteor.datetimetag.substring(0, 8) +
            '/' +
            currentMeteor.datetimetag.substring(8) +
            '/map.jpg'
          "
          alt="Meteor possition marked in map"
        />

        <img
          class="img-fluid"
          :src="
            'https://ildkule.net/data/' +
            currentMeteor.datetimetag.substring(0, 8) +
            '/' +
            currentMeteor.datetimetag.substring(8) +
            '/posvstime.jpg'
          "
          alt="Graph possition vs time"
        />

        <img
          class="img-fluid"
          :src="
            'https://ildkule.net/data/' +
            currentMeteor.datetimetag.substring(0, 8) +
            '/' +
            currentMeteor.datetimetag.substring(8) +
            '/spd_acc.jpg'
          "
          alt="Graph speed - acceleration"
        />

        <img
          class="img-fluid"
          :src="
            'https://ildkule.net/data/' +
            currentMeteor.datetimetag.substring(0, 8) +
            '/' +
            currentMeteor.datetimetag.substring(8) +
            '/orbit.jpg'
          "
          alt="Graph meteor orbit"
        />

        <img
          class="img-fluid"
          :src="
            'https://ildkule.net/data/' +
            currentMeteor.datetimetag.substring(0, 8) +
            '/' +
            currentMeteor.datetimetag.substring(8) +
            '/height.jpg'
          "
          alt="Graph meteor height"
        />
      </div>

      <hr />
      <span v-if="showAdminFeatures">
      <h5>Manuelt bekreftelse av meteorobservasjon</h5>
      <small
        >Obs: Ved å sette "ikke en meteor" vil meteoren forsvinne fra
        meteorlisten for vanlige brukere!</small
      >
      <form>
        <div class="form-group">
          <input
            type="radio"
            id="confirmed"
            value="1"
            v-model="currentMeteor.user_confirmed"
          />
          <label for="confirmed">Bekreftet meteor</label>
          <br />

          <input
            type="radio"
            id="confirmed"
            value="0"
            v-model="currentMeteor.user_confirmed"
          />
          <label for="confirmed">Ikke en meteor</label>
          <br />

          <input
            type="radio"
            id="confirmed"
            value="-1"
            v-model="currentMeteor.user_confirmed"
          />
          <label for="confirmed">Usikker</label>
        </div>
      </form>

      <b-button type="submit" size="sm" @click="updateMeteor">
        Oppdater
      </b-button>
      <p>{{ message }}</p>

      </span>
    </div>
  </div>
</template>

<script>
import MeteorDataService from "../services/meteor.service";
import { toRef } from "vue";
export default {
  props: ["meteor", "meteorData"],
  name: "Meteor",

  setup(props) {
    var currentMeteor = toRef(props, "meteor");
    var currentMeteorData = toRef(props, "meteorData");

    return { currentMeteor, currentMeteorData };
  },

  data() {
    return {
      showDismissibleAlert: null,
      showClearReviewButton: false,
      messageFromReview: "",
      message: "",
    };
  },
  methods: {
    close() {
      this.$emit("closeActiveMeteor");
    },

    convertToLocalDate(dateString) {
      const d = new Date(dateString + " +0000");
      var curr_second = d.getSeconds();
      var curr_minute = d.getMinutes();
      var curr_hour = d.getHours();
      var curr_date = d.getDate();
      var curr_month = d.getMonth() + 1; //Months are zero based
      var curr_year = d.getFullYear();
      return (
        curr_year +
        "-" +
        curr_month.toString().padStart(2, "0") +
        "-" +
        curr_date.toString().padStart(2, "0") +
        " " +
        curr_hour.toString().padStart(2, "0") +
        ":" +
        curr_minute.toString().padStart(2, "0") +
        ":" +
        curr_second.toString().padStart(2, "0")
      );
    },

    classifyMeteor(meteorTag, input) {
      MeteorDataService.classify(meteorTag, input, this.currentUser.id)
        .then((response) => {
          console.log(response.data);
          this.showDismissibleAlert = true;
          this.messageFromReview = response.data.msg;
          console.log(this.messageFromReview);
          if (input != "Clear") {
            this.showClearReviewButton = true;
          } else {
            this.showClearReviewButton = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updateMeteor() {
      MeteorDataService.update(this.currentMeteor.id, this.currentMeteor)
        .then((response) => {
          console.log(response.data);
          this.message = "Meteoren ble oppdatert: " + response.data.msg;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },   
    showAdminFeatures() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
  },

  mounted() {
    this.message = "";
  },
};
</script>