<template>
  <!-- Start loading spinner -->
  <div id="waitspin" v-show="isLoading === true">
    <div class="spinner-border text-primary" style="width: 5rem; height: 5rem" role="status">
      <span class="sr-only">Laster inn...</span>
    </div>
  </div>

  <div class="container-fluid" :class="{ hmm: currentMeteor }">
    <div class="row" style="padding-bottom: 0px; padding-top: 0 px">
      <nav class="navbar navbar-expand-md" aria-label="FilterNavbar" style="padding-bottom: 0px; padding-top: 0 px">
        <div class="mx-auto">
          <button class="navbar-toggler" style="padding-bottom: 10px" type="button" data-bs-toggle="collapse" data-bs-target="#filterbar" aria-controls="filterbar" aria-expanded="false" aria-label="Toggle navigation">
            <a class="navbar-brand mb-1" href="#" style="color: #ececec">Meteorfiltrering</a>
            <span class="fa fa-filter" style="color: #ececec"></span>
          </button>
          <div class="collapse navbar-collapse" id="filterbar">
            <ul class="navbar-nav me-auto mb-2 mb-sm-0">
              <li class="nav-item">
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1">Velg år</button>
                    <div id="collapse-1" class="collapse mt-1">
                      <div v-for="option in yearOptions" :key="option.value">
                        <input type="checkbox" :id="option.value" :value="option.value" v-model="year" @change="meteorFilter" />
                        <label :for="option.value" style="color:white;">{{ option.text }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2">Velg stasjon</button>
                    <div id="collapse-2" class="collapse mt-1">
                      <div v-for="option in stationOptions" :key="option.value">
                        <input type="checkbox" :id="option.value" :value="option.value" v-model="stationName" @change="meteorFilter" />
                        <label :for="option.value"  style="color:white;">{{ option.text }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3">Velg type</button>
                    <div id="collapse-3" class="collapse mt-1">
                      <div v-for="option in typeOptions" :key="option.value">
                        <input type="checkbox" :id="option.value" :value="option.value" v-model="meteorClass" @change="meteorFilter" />
                        <label :for="option.value"  style="color:white;">{{ option.text }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <div class="input-group mb-1">
                  <input type="text" class="form-control" placeholder="Søk i meteorer" aria-label="Søk" v-model="title" @keyup.enter="searchTitle" />
                  <div class="input-group-append">
                    <button class="btn btn-secondary btn-sm" type="button" @click="searchTitle">Søk</button>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <div class="input-group mb-1">
                  <button class="btn btn-secondary btn-sm" type="button" @click="resetFilter">Nullstill</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div class="wrap">
    <div class="box" :class="{ hmm: currentMeteor }" style="min-width: 64vw">
      <div class="row justify-content-center">
        <span v-if="isLoading === false && meteors.length === 0">
          Ingen meteorer er lastet inn
        </span>

        <div class="col" align="center" v-for="(meteor, index) in meteors" :key="index">
          <div class="card">
            <img @click="setActiveMeteor(meteor, index)" class="card-image" :class="{ selected: isSelected(index) }" :src="'https://ildkule.net/img/' + meteor.datetimetag + '_thumbnail.jpg'" alt="Meteor thumbnail" @error="onImageError($event, 'https://ildkule.net/img/missing_thumbnail.png')" :data-replaced="false" />
            <div class="card-body" style="padding-bottom: 5px; padding-top: 5px">
              <p class="card-text">
                <small>
                  <i class="fa fa-clock" alt="Klokkslettikon"></i>
                  {{ convertToLocalDate(meteor.date) }} Lokaltid <br />
                  <i class="fa fa-clock" alt="Klokkslettikon"></i>
                  {{ meteor.date }} UTC <br />
                  <i class="fa-solid fa-location-dot" alt="Stedsikon"></i>
                  {{ meteor.location ? meteor.location : "Ukjent" }} <br />
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="this.meteors.length > 0 && this.perPage <= this.meteors.length" class="col-xs-1 mb-2" align="center" style="padding-bottom: 5rem">
        <button class="btn btn-secondary" @click="loadMore">
          Last inn flere
        </button>
      </div>
    </div>

    <div>
      <div v-if="currentMeteor" class="box" style="margin: 0%; width: 100%" :class="{ yes: currentMeteor }">
        <MeteorComponent :meteor="currentMeteor" :meteorData="currentMeteorData" @closeActiveMeteor="closeActiveMeteor" />
      </div>
      <div v-else></div>
    </div>
  </div>
</template>


<script>
import MeteorDataService from "../services/meteor.service";
import MeteorComponent from "../components/MeteorComponent.vue";

export default {
  name: "meteors-list",
  components: {
    MeteorComponent,
  },
  data() {
    return {
       // Pagination properties
       currentPage: 1,
      perPage: 10,
      orderby: "date",
      order: "desc",
      // Alert properties
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      // Review properties
      messageFromReview: "",
      showClearReviewButton: false,
      // Loading state
      isLoading: false,
      // Meteor properties
      meteors: [],
      currentMeteor: null,
      currentMeteorData: null,
      currentIndex: -1,
      count: 0,
      totalMeteors: 0,
      // Display properties
      meteorsToShow: 15,
      length: 20,
      isFinished: false,
      row: 0, 
      // Filter properties
      keyword: "",
      title: "",
      pageSizes: [3, 6, 9],
      isHidden: true,
      selected: [],
      stationName: [],
      year: [],
      meteorClass: [],

      yearOptions: this.generateYearOptions(2015, new Date().getFullYear()),

      stationOptions: [
        {
          text: "Sorreisa",
          value: "sorreisa",
          class: "dropdown-menu",
        }, // Her dynamisk hente inn options / år
        { text: "Larvik", value: "larvik" },
        { text: "Trondheim", value: "trondheim" },
        { text: "Kristiansand", value: "kristiansand" },
        { text: "Gaustatoppen", value: "gaustatoppen" },
        { text: "Voksenlia", value: "voksenlia" },
        { text: "Harestua", value: "harestua" },
        { text: "Orsta", value: "orsta" },
      ],
      typeOptions: [
        {
          text: "Krysspeilet",
          value: "krysspeilet",
          class: "dropdown-menu",
        }, // Her dynamisk hente inn options / år (Meteorkandidat er for langt for layout, flytter dropdown)

        { text: "Upeilet", value: "upeilet" },
        { text: "Meteorittkandidat", value: "meteorittkandidat" },
      ],
    };
  },

  methods: {
    //method for calculating local time from UTC
    convertToLocalDate(dateString) {
      const d = new Date(dateString + " +0000");
      var curr_second = d.getSeconds();
      var curr_minute = d.getMinutes();
      var curr_hour = d.getHours();
      var curr_date = d.getDate();
      var curr_month = d.getMonth() + 1; //Months are zero based
      var curr_year = d.getFullYear();
      return (
        curr_year +
        "-" +
        curr_month.toString().padStart(2, "0") +
        "-" +
        curr_date.toString().padStart(2, "0") +
        " " +
        curr_hour.toString().padStart(2, "0") +
        ":" +
        curr_minute.toString().padStart(2, "0") +
        ":" +
        curr_second.toString().padStart(2, "0")
      );
    },

    onImageError(event, fallbackSrc) {
      if (event.target.dataset.replaced === "false") {
        event.target.src = fallbackSrc;
        event.target.dataset.replaced = "true";
      }
    },

    generateYearOptions(startYear, endYear) {
      const yearOptions = [];
      for (let year = endYear; year >= startYear; year--) {
        yearOptions.push({
          text: year.toString(),
          value: year.toString(),
          class: "dropdown-menu",
        });
      }
      return yearOptions;
    },

    searchTitle() {
      this.isLoading = true;
      MeteorDataService.search(this.title)
        .then((response) => {
          const { meteors, totalItems } = response.data;
          this.meteors = meteors;
          this.totalMeteors = totalItems;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
      this.isLoading = false;
    },
    meteorFilter() {
      this.isLoading = true;

      MeteorDataService.getAllByFilter(
        this.stationName.join(),
        this.year.join(),
        this.meteorClass.join()
      )
        .then((response) => {
          const { meteors, totalItems } = response.data;
          this.meteors = meteors;
          this.totalMeteors = totalItems;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
      this.isLoading = false;
    },
    setActiveMeteor(Meteor, index) {
      this.isLoading = true;
      MeteorDataService.get(Meteor.id)
        .then((response) => {
          var meteorDataResponse = response.data;
          for (
            let index = 0;
            index < meteorDataResponse.observation_cam_data.length;
            ++index
          ) {
            meteorDataResponse.observation_cam_data[index].videourl =
              "https://ildkule.net/data/" +
              meteorDataResponse.datetimetag.substring(0, 8) +
              "/" +
              meteorDataResponse.datetimetag.substring(8) +
              "/" +
              meteorDataResponse.observation_cam_data[index].cam.station
                .station_name +
              "/" +
              meteorDataResponse.observation_cam_data[index].cam.cam_name +
              "/" +
              meteorDataResponse.observation_cam_data[index].cam.station
                .station_name +
              "-" +
              meteorDataResponse.datetimetag +
              "-gnomonic.mp4";
          }
          this.currentMeteorData = meteorDataResponse;
          this.currentMeteor = Meteor;
          this.currentIndex = Meteor ? index : -1;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },

    classifyMeteor(meteorTag, input) {
      MeteorDataService.classify(meteorTag, input, this.currentUser.id)
        .then((response) => {
          console.log(response.data);
          this.showDismissibleAlert = true;
          this.messageFromReview = response.data.msg;
          if (input != "Clear") {
            this.showClearReviewButton = true;
          } else {
            this.showClearReviewButton = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    hoverCard(Meteor, index) {
      this.currentMeteor = Meteor;
      this.currentIndex = Meteor ? index : -1;
    },
    isSelected(index) {
      return this.currentIndex === index;
    },
    closeActiveMeteor() {
      this.currentMeteor = null;
      this.currentIndex = -1;
    },
    loadMore() {
      this.isLoading = true;
      console.log(this.perPage);
      if (this.perPage >= this.meteors.length + 1) return;
      this.perPage = this.perPage + 10;
      this.retrieveMeteors();
      this.isLoading = false;
    },
    getRequestParams(searchTitle, page, pageSize) {
      let params = {};
      if (searchTitle) {
        params["title"] = searchTitle;
      }
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      return params;
    },
    refreshPage() {
      this.isLoading = true;
      window.location.reload();
      this.isLoading = false;
    },
    retrieveMeteors() {
      this.isLoading = true;
      MeteorDataService.getMeteorsPaginated(
        this.currentPage,
        this.perPage,
        this.orderby,
        this.order
      )
        .then((response) => {
          const { meteors, totalItems } = response.data;
          this.meteorcolumns = Object.keys(meteors[0]);
          this.meteors = meteors;
          this.count = parseInt(totalItems);
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
      this.isLoading = false;
    },
    resetFilter() {
      this.title = "";
      this.stationName = [];
      this.year = [];
      this.meteorClass = [];
      this.retrieveMeteors();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  computed: {
    meteorsLoaded() {
      if (this.meteors) return this.meteors.slice(0, this.length);
      return [];
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    flattenSelect() {
      // Slår sammen valgte verdier fra checkboxes
      return this.selected.join();
    },

    computedClass() {
      let className = "col-sm-8";
      let className2 = "col-sm-12";
      // More complicated logic to determine what
      // class should be applied
      if (this.currentMeteor) return className;
      return className2;
    },
  },
  mounted() {
    this.isLoading = true;
    let hasFilter = false;
    if (typeof this.$route.query.meteortypefilter != "undefined") {
      this.meteorClass.push(this.$route.query.meteortypefilter.toLowerCase());
      hasFilter = true;
    }
    if (typeof this.$route.query.stasjonsfilter != "undefined") {
      this.stationName.push(this.$route.query.stasjonsfilter.toLowerCase());
      hasFilter = true;
    }
    if (hasFilter) {
      this.meteorFilter();
    } else {
      this.retrieveMeteors();
    }
    this.totalMeteors = this.meteors.length;
    this.isLoading = false;
  },
};
</script>

<style>
#waitspin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.box::-webkit-scrollbar {
  display: none;
}
.row {
  padding-bottom: 0.2em;
}
.vertical-scrollable > .row {
  overflow-y: scroll;
}
.card {
  margin-bottom: 3%;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}
.card-image {
  /* the other rules */
  transition: height 0.3s, opacity 0.3s;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.3);
}
.card-image.selected {
  opacity: 0.3;
}
#example {
  background-repeat: repeat-x;
  margin-left: 0px;
  margin-top: 0x;
}
#example .but1 {
  line-height: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  display: inline-block;
  position: relative;
}
#example .but1 a {
  position: absolute;
  top: 4px;
  left: 5px;
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 500px) {
  /* Styles */

  .box {
    height: 100vh;
    overflow: scroll;
    z-index: 500;
   /*  background-color: #f0efed; */
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
  }
  /*holdt av plass til .yes */
  .wrap {
    height: 0%;
    margin-right: 0%;
    display: flex;
  }
  /*boksen som åpnes ved klikk på meteor*/
  .yes {
    left: 0px;
    right: 0px;
    z-index: 500;
    position: absolute;
    border: solid;
    border-color: #f0efed;
  }
  /*Knappen for x-ut TOP: MÅ JUSTERES NÅR FILTERBAR ER FERDIG, FOR RETT POSSISJONERING 
  for øyeblikket satt slik at man når den med tommelen på mobil...(brukervennlighet)*/
  .abs {
    position: fixed;
    right: 0.2rem;
    bottom: 5rem;
    z-index: 550;
  }
  /*bildestørrelse på home-forside 179*/
  .card-image {
    margin-top: 0%;
    height: 179px;
    width: auto;
  }
}
@media only screen and (min-width: 500px) {
  /* For desktop: */
  .box {
    height: 100vh;
    margin-right: 1em;
    overflow: scroll;
  }

  /* Sørge for å klare to meteorer i høyden på vanlig full hd skjerm. Mobil kan kjøre full bredde*/
  .meteorlistbox {
    max-width: 240px;
  }

  .wrap {
    height: 0%;
    margin-right: 0%;
    display: flex;
  }

  /* X -knapp ut valgt meteor*/
  .abs {
    position: fixed;
    right: 1rem;
    top: 10rem;
  }

  .nav-filter-item {
    margin-left: 1%;
  }

  /*boksen som åpnes ved klikk på meteor*/
  .yes {
    min-width: 30vw;
    right: 0px;
  }
}
</style>
