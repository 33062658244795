<template>
  <nav class="navbar navbar-expand-md" aria-label="Navbar">
    <div class="container-fluid">
      <!-- Logo -->
      <router-link to="/" class="nav-link">
        <div style="width: 100px; height: 80px; margin-top:-15px; margin-bottom:-5px; overflow: hidden;">
          <img alt="Norsk meteornettverks logo" style="width: 100%; height: 130%;" src="../assets/cropped-nmn2-1.png" />
        </div>
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarApp"
        aria-controls="navbarApp" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon" style=" filter: invert(1);"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarApp" style="padding-bottom: 0px">
        <ul class="navbar-nav me-auto mb-2 mb-sm-0">
          <!-- Home/Meteor -->
          <li class="nav-item">
            <router-link to="/" class="nav-link"> Siste meteorer </router-link>
          </li>
          <!-- Rapporter meteor -->
          <li class="nav-item">
            <router-link to="/rapportermeteor" class="nav-link">
              Rapporter ny meteor
            </router-link>
          </li>
          <!-- Om oss -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="dropdownOmOss" data-bs-toggle="dropdown"
              aria-expanded="false">
              Om oss
            </a>
            <!-- Dropdown menu -->
            <ul class="dropdown-menu" aria-labelledby="dropdownOmOss">
              <li class="dropdown-item">
                <router-link to="/omoss" class="nav-link">
                  Norsk Meteornettverk
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link to="/omoss/samarbeidspartnere" class="nav-link">
                  Samarbeidspartnere
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link to="/omoss/kontaktoss" class="nav-link">
                  Kontakt oss
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="showAdminBoard" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="dropdownOmOss" data-bs-toggle="dropdown"
              aria-expanded="false">
              Administrasjon
            </a>
            <!-- Dropdown menu -->
            <ul class="dropdown-menu" aria-labelledby="dropdownOmOss">
              <li class="dropdown-item">
                <router-link to="/administrasjon/meteoradministrasjon" class="nav-link">
                  Meteoradministrasjon
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link to="/administrasjon/brukeradministrasjon" class="nav-link">
                  Brukeradministrasjon
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link to="/administrasjon/stasjonslogger" class="nav-link">
                  Stasjonslogger
                </router-link>
              </li>
            </ul>
          </li>
          <!-- Statistikk -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="dropdownStatistikk" data-bs-toggle="dropdown"
              aria-expanded="false">
              Utforsk
            </a>
            <!-- Dropdown menu -->
            <ul class="dropdown-menu" aria-labelledby="dropdownStatistikk">
              <li class="dropdown-item">
                <router-link to="/statistikk/dashboard" class="nav-link">
                  Oversikt
                </router-link>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li class="dropdown-item">
                <router-link to="/statistikk/dashboard/nokkeltall" class="nav-link">
                  Nøkkeltall
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link to="/statistikk/dashboard/innsikt" class="nav-link">
                  Innsikt
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link to="/statistikk/dashboard/meteorer" class="nav-link">
                  Meteorer
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link to="/statistikk/dashboard/meteorkart" class="nav-link">
                  Meteorkart
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link to="/statistikk/dashboard/kameranettverk" class="nav-link">
                  Kameranettverk
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link to="/statistikk/dashboard/datanedlasting" class="nav-link">
                  Datanedlasting
                </router-link>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li class="dropdown-item">
                <router-link to="/statistikk/dashboardgammel" class="nav-link">
                  Dashboard (eldre test)
                </router-link>
              </li>

            </ul>
          </li>
          <!-- Statistikk -->
          <!-- Dropdown menu -->
          <ul class="dropdown-menu" aria-labelledby="dropdownOmOss">
            <li class="dropdown-item">
              <router-link to="/statistikk/dashboard" class="nav-link">
                Meteordashboard
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/statistikk/dashboardgammel" class="nav-link">
                Dashboard (eldre test)
              </router-link>
            </li>
          </ul>
          <!-- Register -->
          <li v-if="!currentUser" class="nav-item">
            <router-link to="/registrerdeg" class="nav-link">
              <i class="fas fa-user-plus" alt="Registrerdegikon"></i> Registrer deg
            </router-link>
          </li>
          <!-- Login -->
          <li v-if="!currentUser" class="nav-item">
            <router-link to="/innlogging" class="nav-link">
              <i class="fas fa-sign-in-alt" alt="Logg inn-ikon"></i> Logg inn
            </router-link>
          </li>
          <li v-if="currentUser" class="nav-item">
            <router-link to="/brukerprofil" class="nav-link">
              <i class="fa-solid fa-user" alt="Profilsideion"></i>
              {{ currentUser.username }}
            </router-link>
          </li>
          <li v-if="currentUser" class="nav-item">
            <a class="nav-link" @click.prevent="logOut">
              <i class="fas fa-sign-out-alt" alt="Logg ut-ikon"></i> Logg ut
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_USER");
      }
      return false;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/innlogging");
    },
  },
};
</script>

<style scoped>
nav {
  /*  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 3px rgb(0, 0, 0, 0.3); */
}

nav .navbar-nav li a {
  color: white !important;
}

.nav-link:hover {
  color: white !important;
  text-decoration: underline white;
}

.dropdown-menu {
  background-color: #212529 !important;
}

.dropdown-item {
  color: #9b9d9e !important;
}

.dropdown-item:hover {
  color: #212529 !important;
  background-color: #9b9d9e !important;
}

/* #app {
  position: relative;
  min-height: 100vh;
  padding-bottom: 6rem;
} */

/* #footer {
  position: absolute;
  bottom: 0;
  width: 100%;
} */

/* Navbar text size for mobile phones */
@media only screen and (max-width: 767px) {

  nav,
  .dropdown-menu {
    font-size: medium;
  }
}</style>
