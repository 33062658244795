<template>
  <div id="app" :style="{ backgroundImage: `url(${bgImage})`, backgroundRepeat: 'repeat' }" class="app">

    <!-- Menybar øverst-->
    <MainNavbar />

    <!-- Container for alle side komponentene -->
    <div class="container-fluid"><router-view /></div>

    <!-- Footer component - se Footer.vue -->
    <!-- <MainFooter /> -->
  </div>

  <!-- Start DEMOTAG (use when system is in demo or test mode -->
  <div style="color: white; position: fixed; top: 0; right: 90px">
    DEMOVERSJON
  </div>
  <!-- End DEMOTAG (use when system is in demo or test mode -->
</template>

<script>
import MainNavbar from "./components/NavigationHeader.vue";
/* import MainFooter from "./components/NavigationFooter.vue"; */
import axios from "axios";
import store from './store/index.js';
import BackgroundImage from '@/assets/meteor_background_larvik.jpg';


export default {
  name: "Norsk meteornettverks observasjoner",
  components: {
    MainNavbar,
    /* MainFooter, */
  },

  data() {
    return {
      bgImage: BackgroundImage,
    };
  },

  created: function () {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error.response.data);
        if (error.response.status === 401) {
          store.dispatch("auth/logout");
          console.log("utlogget");
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style scoped>
#app {
  position: relative;
  min-height: 95vh;
  padding-bottom: 2rem;
}

::v-deep .card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
}
</style>

<style>
/* google recaptcha - terms and conditions are shown crearly in the forms where google recaptcha is used and we are derfore hiding the icon that messes up the layout - as per recaptcha guidelines */
.grecaptcha-badge {
  visibility: hidden;
}

.app {
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
}

body {
  font-family: 'Libre Franklin', Arial, sans-serif;
}




.chart-gradient {
  background-image: linear-gradient(135deg, #32373D 5%, #171D22);
}
</style>