import axios from "axios";
import authHeader from './auth-header';
import getConfig from './config';

const API_URL = getConfig();

class MeteorService {
  getAll() {
    return axios.get(API_URL + "meteors");
  }

  get(id) {
    return axios.get(API_URL + "meteor/" + id);
  }

  update(id, data) {
    return axios.put(API_URL + "meteor/" + id, data,  { headers: authHeader() });
  }

  getStationReport() {
    return axios.get(API_URL + "insight/station");
  }
  getCamReport() {
    return axios.get(API_URL + "insight/cam");
  }

  getLog() {
    return axios.get(API_URL + "stationlog");
  }

  getTotalReport() {
    return axios.get(API_URL + "insight/total");
  }

  getCoordinates() {
    return axios.get(API_URL + "insight/coordinates");
  }

  classify(meteorTag, input, userid) {
    const data = { meteorID: meteorTag,
      confirmed: input,
      userID: userid};
    return axios.post(API_URL + 'meteor/'+meteorTag+"/review", data,  { headers: authHeader() });
  } 

  getAllByFilter(stationName, year, meteorClass) {    
    return axios.get(
      API_URL +
        "meteors?stationName=" +
        stationName +
        "&year=" +
        year +
        "&meteorClass=" +
        meteorClass
    );
  }


  getPage(page) {    
    return axios.get(
      API_URL +
        "meteors?page=" +
        page 
    );
  }

  getMeteorsPaginated(page, perpage, orderby, order) {    
    return axios.get(
      API_URL +
        "meteors?page="+page+"&limit="+perpage+"&orderby="+orderby+"&order="+order 
    );
  }

  getAllLimit(limit) {    
    return axios.get(
      API_URL +
        "meteors?page=1&limit" +
        limit 
    );
  }


  search(value) {
    return axios.get(API_URL + "meteors?searchTerm=" + value);
  }
}

export default new MeteorService();
