const language = {
  namespaced: true,  
  state: {
    selectedLanguage: "no", 
  },
  mutations: {
    setLanguage(state, language) {
      state.selectedLanguage = language;
    },
  },
  actions: {
    setLanguage({ commit }, language) {
      commit("setLanguage", language);
      console.log("Language set to: " + language);
      // Save the language preference in cookies or any other storage mechanism here
    },
  },
  getters: {
    selectedLanguage: (state) => state.selectedLanguage,
  },
};

export default language;
