import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue3 from 'bootstrap-vue-3';
/* import 'bootstrap/dist/css/bootstrap.css'; */
import { VueReCaptcha } from 'vue-recaptcha-v3'


createApp(App)
  .use(BootstrapVue3)
  .use(router)
  .use(store)
  .use(VueReCaptcha, { siteKey: '6Lcg4D8gAAAAAEHMDgeuqg7RbERkYSogmrEYCb6_' })
  .mount("#app");