import Vuex from "vuex";
import { auth } from "./auth.module";
import { dashboard } from "./dashboard.module";
import language from "./language.module"; 

const store = new Vuex.Store({
  modules: {
    auth,
    dashboard,
    language, 
  },
});

export default store;
