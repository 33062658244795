import { createRouter, createWebHistory } from "vue-router";
import SisteRegistrerteMeteorer from '../views/SisteRegistrerteMeteorer.vue'

// Importing views dynamically to improve performance
const Meteor = () => import('../views/Meteor/Meteor.vue')
const Dashboard = () => import('../views/Statistikk/Dashboard.vue')
const DashboardGammel = () => import('../views/Statistikk/DashboardGammel.vue')
const RapporterMeteor = () => import('../views/RapporterMeteor/RapporterMeteor.vue')
const Innlogging = () => import('../views/Innlogging/Innlogging.vue')
const GlemtPassord = () => import('../views/Innlogging/GlemtPassord.vue')
const Brukerprofil = () => import('../views/Brukerprofil/Brukerprofil.vue')
const RegistrerDeg = () => import('../views/Brukerprofil/RegistrerDeg.vue')
const NullstillPassord = () => import('../views/Brukerprofil/NullstillPassord.vue')
const Samarbeidspartnere = () => import('../views/OmOss/Samarbeidspartnere.vue')
const KontaktOss = () => import('../views/OmOss/KontaktOss.vue')
const OmOss = () => import('../views/OmOss/OmOss.vue')
const MeteorAdministrasjon = () => import('../views/Administrasjon/MeteorAdministrasjon.vue')
const BrukerAdministrasjon = () => import('../views/Administrasjon/BrukerAdministrasjon.vue')
const Stasjonslogger = () => import('../views/Administrasjon/Stasjonslogger.vue')
const MeteorDetails = () => import('../components/MeteorDetails.vue')

const Nokkeltall  = () => import( '../views/Statistikk/Visualiseringer/Nokkeltall.vue');
const Innsikt  = () => import( '../views/Statistikk/Visualiseringer/Innsikt.vue');
const Meteorkart  = () => import( '../views/Statistikk/Visualiseringer/Meteorkart.vue');
const Meteorer  = () => import( '../views/Statistikk/Visualiseringer/Meteorer.vue');
const Datanedlasting  = () => import( '../views/Statistikk/Visualiseringer/Datanedlasting.vue');
const Kameranettverk  = () => import( '../views/Statistikk/Visualiseringer/Kameranettverk.vue');


// Defining routes
const routes = [
  {
    path: "/",
    component: SisteRegistrerteMeteorer,
    meta: {
      title: 'De siste meteorene fra Norsk Meteornettverk',
      metaTags: [
        {
          name: 'description',
          content: 'Norsk meteorsnetteverks siste registrerte meteorer. Søk og filtrer for å se interessante meteorobservasjoner Vi har tusenvis av meteorer i vårt register!'
        },
        {
          name: 'keywords',
          content: 'Meteor, ildkule, himmelen, verdensrommet, astronomi, meteorvideo, meteorbilde'
        },
        {
          property: 'og:description',
          content: 'Norsk meteorsnetteverks siste registrerte meteorer. Søk og filtrer for å se interessante meteorobservasjoner Vi har tusenvis av meteorer i vårt register!'
        }]

    }
  },

  {
    path: "/sisteregistrertemeteorer",
    component: SisteRegistrerteMeteorer,
    meta: { title: 'De siste registrerte meteorer fra Norsk Meteornettverk' }
  },

  {
    path: "/meteor/:id",
    name: "Meteor",
    component: Meteor,
    meta: { title: 'Meteordetaljer' }
  },

  {
    path: "/statistikk/meteor/:id",
    name: "MeteorDetails",
    component: MeteorDetails,
    meta: { title: 'Detaljer for meteoren' },
    props: route => ({ id: route.params.id }),
  },

  {
    path: "/statistikk/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { title: 'Dashboard - oversikt i tall over registrerte meteorer' }
  },


  {
    path: '/statistikk/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { title: 'Dashboard - oversikt i tall over registrerte meteorer' },
    children: [
      {
        path: 'nokkeltall',
        component: Nokkeltall,
      },
      {
        path: 'innsikt',
        component: Innsikt,
      },
      {
        path: 'meteorkart',
        component: Meteorkart,
      },
      {
        path: 'meteorer',
        component: Meteorer,
      },
      
      {
        path: 'datanedlasting',
        component: Datanedlasting,
      },
      
      {
        path: 'kameranettverk',
        component: Kameranettverk,
      },
    ],
  },

  {
    path: "/statistikk/dashboardgammel",
    name: "Dashboard gammel testversjon",
    component: DashboardGammel,
    meta: { title: 'Dashboard - oversikt i tall over registrerte meteorer' }
  },
  {
    path: "/innlogging",
    name: "Innlogging",
    component: Innlogging,
    meta: { title: 'Innlogging' }
  },
  {
    path: "/innlogging/glemtpassord",
    name: "GlemtPassord",
    component: GlemtPassord,
    meta: { title: 'Glemt passord?' }
  },
  {
    path: "/registrerdeg",
    name: "RegistrerDeg",
    component: RegistrerDeg,
    meta: { title: 'Registrer deg - lag ny bruker' }
  },
  {
    path: "/rapportermeteor",
    name: "RapporterMeteor",
    component: RapporterMeteor,
    meta: { title: 'Rapporter en meteor du har sett' }
  },
  {
    path: "/brukerprofil",
    name: "Brukerprofil",
    component: Brukerprofil,
    meta: { title: 'Din brukerprofilside' }
  },
  {
    path: "/brukerprofil/nullstillpassord",
    name: "NullstillPassord",
    component: NullstillPassord,
    meta: { title: 'Nullstill passord' }
  },
  {
    path: "/omoss/samarbeidspartnere",
    name: "Samarbeidspartnere",
    component: Samarbeidspartnere,
    meta: { title: 'Våre samarbeidspartnere' }
  },
  {
    path: "/omoss/kontaktoss",
    name: "KontaktOss",
    component: KontaktOss,
    meta: { title: 'Kontakt oss' }
  },
  {
    path: "/omoss",
    name: "OmOss",
    component: OmOss,
    meta: { title: 'Om Norsk Meteornettverk' }
  },
  {
    path: "/administrasjon/meteoradministrasjon",
    name: "MeteorAdministrasjon",
    component: MeteorAdministrasjon,
    meta: { title: 'Meteoradministrasjon' }
  },
  {
    path: "/administrasjon/brukeradministrasjon",
    name: "BrukerAdministrasjon",
    component: BrukerAdministrasjon,
    meta: { title: 'Brukeradministrasjon' }
  },

  {
    path: "/administrasjon/stasjonslogger",
    name: "Stasjonslogger",
    component: Stasjonslogger,
    meta: { title: 'Stasjonslogger' }
  },

];

const router = createRouter({
  history: createWebHistory(), //enable the browser's back and forward buttons to work with Vue Router
  routes,
});


router.beforeEach((to, from, next) => {
  // This code will be executed before each navigation to a new route
  // to: the target Route Object being navigated to
  // from: the current Route Object being navigated away from
  // next: a function that must be called to resolve the navigation
  //       pass an argument to next to allow the navigation, or call next(false) to cancel it

  // Remove the 'show' class from the 'navbarApp' element, hiding the navbar
  document.getElementById('navbarApp').classList.remove('show');

  // Public pages, pages that don't require authentication
  const publicPages = ['/innlogging', '/', '/sisteregistrertemeteorer', '/meteor', '/registrerdeg', '/omoss', '/omoss/kontaktskjema', '/omoss/samarbeidspartnere', '/rapportermeteor', '/statistikk/dashboard', '/brukerprofil/nullstillpassord', '/innlogging/glemtpassord'];

  // Check if the current page requires authentication
  const authRequired = !publicPages.includes(to.path);

  // Default title for a application page view
  const DEFAULT_TITLE = 'Norsk Meteornettverk';

  // Set custom name for the page based on its title and any parameters in the URL
  let customName = to.params.id ? (to.meta.title + ' ' + to.params.id || DEFAULT_TITLE) : (to.meta.title || DEFAULT_TITLE);
  document.title = customName;

  // Find the nearest Route Object with a metaTags property
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // Remove any existing meta tags that were added by Vue Router
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // If there is no nearest Route Object with a metaTags property, allow the navigation to proceed
  if (!nearestWithMeta) return next();

  // Otherwise, add the meta tags specified in the Route Object's metaTags property
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    .forEach(tag => document.head.appendChild(tag));

  // Check if the user is logged in
  const loggedIn = localStorage.getItem('user');

  // If the current page requires authentication and the user is not logged in, redirect to the login page
  if (authRequired && !loggedIn) {
    next('/innlogging');
  } else {
    // Otherwise, allow the navigation to proceed
    next();
  }
});



export default router;
